/** @format */

import * as types from '../constants/item';

export const itemInitialState = {
  update: {
    
  }
 };

export default (state = itemInitialState, action) => {
  switch (action.type) {


      case types.GET_ITEM_REQUEST:
        return {
          ...state,
          [action.payload]: {
            requesting: true,
            success: false,
            error: null,
          },
        };

    case types.GET_ITEM_ERROR:
      return {
        ...state,
        [action.payload.item]: {
          requesting: false,
          success: false,
          error: action.payload.error,
        },
      };

    case types.GET_ITEM_SUCCESS:
      return {
        ...state,
        [action.payload.item]: {
          requesting: false,
          success: true,
          error: null,
          data: action.payload.data,
        },
      };

      /** PAGES */
      case types.NEXT_PAGE:
      return {
        ...state,
        [`${action.payload.item}_nxt`]: {
          page : action.payload.page
        },
      };

      case types.PREVIOUS_PAGE:
        return {
          ...state,
          [`${action.payload.item}_prv`]: {
            page : action.payload.page
          },
        };


        /** UPDATES */
        case types.UPDATE_ITEM_REQUEST:
          return {
            ...state,
            update : {
              [action.payload]: {
                requesting: true,
                success: false,
                error: null,
              },
            }
          };
  
      case types.UPDATE_ITEM_ERROR:
        return {
          ...state,
          update: {
            [action.payload.item]: {
              requesting: false,
              success: false,
              error: action.payload.error,
            },
          }
        };
  
      case types.UPDATE_ITEM_SUCCESS:
        return {
          ...state,
          update: {
            [action.payload]: {
              requesting: false,
              success: true,
              error: null,
             },
          }
        };

    default:
      return state;
  }
};
