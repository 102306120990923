import React, { Suspense, lazy } from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { history } from './configureStore';
import NotFound from './pages/404';
import RequiredAuth from './components/RequiredAuth';
import DashBoard from './pages/Dashbord';
import 'semantic-ui-less/semantic.less';

//******/
const Login = lazy(() => import('./pages/Login'));
const Home = lazy(() => import('./pages/home.View'));
const Payments = lazy(() => import('./pages/payments.main'));
const Feedbacks = lazy(() => import('./pages/feedbacks.main'));
const Improvements = lazy(() => import('./pages/improvements.main'));
const Ratings = lazy(() => import('./pages/ratings.main'));
const Fares = lazy(() => import('./pages/fares.main'));
const TraficUpdates = lazy(() => import('./pages/traficUpdates.main'));
const PromoCode = lazy(() => import('./pages/promoCode'));
const PaymentReversal = lazy(() => import('./pages/paymentreversal'));
const PromoUsage = lazy(() => import('./pages/promousage'));
const ReferralUsage = lazy(() => import('./pages/referralusage'));
const Sms = lazy(() => import('./pages/sms'));
const HalfPayment = lazy(() => import('./pages/halfPayment'));

const Transactions = lazy(() => import('./pages/transactions.main'));
const DenialList = lazy(() => import('./pages/denialList.main'));
const Debtors = lazy(() => import('./pages/debtors.main'));
const Merchants = lazy(() => import('./pages/merchantAccounts.main'));
const Authorizations = lazy(() => import('./pages/authorization.main'));
const PromoCodesView = lazy(() => import('./pages/promoCodesView'));
const Users = lazy(() => import('./pages/users'));

class App extends React.Component {
  render() {
    return (
      <div style={{ height: '100%' }}>
        <Suspense fallback={<p> Loading.....</p>}>
          <Router history={history}>
            <Switch>
              <Route exact path='/login' render={() => <Login />} />
              <Route exact path='/dashboard/home' component={RequiredAuth(Home)} />
              <Route
                exact
                path='/dashboard/payments'
                component={RequiredAuth(Payments)}
              />
              <Route
                exact
                path='/dashboard/feedbacks'
                component={RequiredAuth(Feedbacks)}
              />
              <Route
                exact
                path='/dashboard/improvements'
                component={RequiredAuth(Improvements)}
              />
              <Route exact path='/dashboard/ratings' component={RequiredAuth(Ratings)} />
              <Route exact path='/dashboard/fares' component={RequiredAuth(Fares)} />

              <Route
                exact
                path='/dashboard/traficUpdates'
                component={RequiredAuth(TraficUpdates)}
              />

              <Route
                exact
                path='/dashboard/promoCode'
                component={RequiredAuth(PromoCode)}
              />

              <Route
                exact
                path='/dashboard/transactions'
                component={RequiredAuth(Transactions)}
              />

              <Route
                exact
                path={'/dashboard/denialList'}
                component={RequiredAuth(DenialList)}
              />

              <Route exact path='/dashboard/debtors' component={RequiredAuth(Debtors)} />
              <Route
                exact
                path='/dashboard/merchantAccounts'
                component={RequiredAuth(Merchants)}
              />

              <Route
                exact
                path='/dashboard/authorizations'
                component={RequiredAuth(Authorizations)}
              />
              <Route
                exact
                path='/dashboard/promousage'
                component={RequiredAuth(PromoUsage)}
              />

              <Route
                exact
                path='/dashboard/referralusage'
                component={RequiredAuth(ReferralUsage)}
              />
              <Route
                exact
                path='/dashboard/paymentreversal'
                component={RequiredAuth(PaymentReversal)}
              />
              <Route exact path='/dashboard/sms' component={RequiredAuth(Sms)} />
              <Route
                exact
                path='/dashboard/halfPayment'
                component={RequiredAuth(HalfPayment)}
              />
              <Route
                exact
                path='/dashboard/promocodes'
                component={RequiredAuth(PromoCodesView)}
              />
              <Route
                exact
                path='/dashboard/users'
                component={RequiredAuth(Users)}
              />

              <Route to='/dashboard' render={(props) => <Home {...props} />} />
              <Route path={'/:404_path'} component={NotFound} />
            </Switch>
          </Router>
        </Suspense>
      </div>
    );
  }
}

export default DashBoard(App);
