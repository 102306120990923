/** @format */

import axios from 'axios';
require('dotenv').config();

let url = 'http://masstransit.eu-west-1.elasticbeanstalk.com/'

const getDenial = (filters) => {
  let accessurl = `${url}api/denylist/current`;
  return axios
    .post(accessurl, {...filters})
    .then((data) => data)
    .catch((error) => {
      throw error.response;
    });
};

const getDenialHistory = (filters) => {
  let accessurl = `${url}api/denylist/history`;
  return axios
    .post(accessurl, {...filters})
    .then((data) => data)
    .catch((error) => {
      throw error.response;
    });
};

export { getDenial, getDenialHistory };
