import * as types from '../constants/auth';

export const authInitialState = {
  requesting: false,
  success: false,
  error: null,
  data: {}
};


export default (state = authInitialState, action) => {
	switch (action.type) {

		case types.LOGIN_REQUEST:
			return {
        requesting: true,
        success: false,
				error: null
      };
      
      case types.LOGIN_ERROR:
			return {
        requesting: false,
        success: false,
				error: action.payload
      };
      
      case types.LOGIN_SUCCESS:
			return {
				requesting: false,
        success: true,
        error:  null,
        data: action.payload,
			};

		default:
			return state;
	}
}
