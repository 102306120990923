/** @format */

import axios from 'axios';
require('dotenv').config();

let url = 'http://masstransit.eu-west-1.elasticbeanstalk.com/';

const getAllTransactions = async (filters) => {
  let accessurl = `${url}api/tap/getalltaps`;
  delete filters.status;
  return axios
    .post(accessurl, { ...filters })
    .then((data) => data)
    .catch((error) => {
      throw error.response;
    });
};

const getApprovedTransactions = async (filters) => {
  let accessurl = `${url}api/auths/getapproved`;
  return axios
    .post(accessurl, { ...filters })
    .then((data) => data)
    .catch((error) => {
      throw error.response;
    });
};

const getDeclinedTransactions = async (filters) => {
  let accessurl = `${url}api/auths/getdeclined`;
  return axios
    .post(accessurl, { ...filters })
    .then((data) => data)
    .catch((error) => {
      throw error.response;
    });
};

const getAuthorizations = async (filters) => {
  let accessurl = `${url}api/auths/getauths`;
  return axios
    .post(accessurl, { ...filters })
    .then((data) => data)
    .catch((error) => {
      throw error.response;
    });
};

export { getAllTransactions, getApprovedTransactions, getDeclinedTransactions, getAuthorizations };
