/** @format */

import * as types from '../constants/promocode';

export const codeInitialState = {
  requesting: false,
  success: false,
  error: null,
};

export default (state = codeInitialState, action) => {
  switch (action.type) {
    case types.ADD_CODE_REQUEST:
      return {
        requesting: true,
        success: false,
        error: null,
      };

    case types.ADD_CODE_ERROR:
      return {
        requesting: false,
        success: false,
        error: action.payload,
      };

    case types.ADD_CODE_SUCCESS:
      return {
        requesting: false,
        success: true,
        error: null,
      };

    default:
      return state;
  }
};
