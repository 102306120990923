/** @format */

import * as types from '../constants/auth';
import { User } from '../components/configs';
import { history } from '../configureStore';
import errors from '../errors';
import { authApi } from '../APIroutes'

const { login } = authApi

export function loginRequest(promise) {
  return {
    type: types.LOGIN_REQUEST,
    payload: promise,
  };
}

export function loginError(error) {
  return {
    type: types.LOGIN_ERROR,
    payload: error,
  };
}


export function loginSuccess(user) {
  User.setUser(user);
  return {
    type: types.LOGIN_SUCCESS,
    payload: user,
  };
}


export const resetLogin = () => {
  return {
    type: 'CLEAR_STORE',
  };
};


export function loginTheUser(values) {
  return async function(dispatch) {
    dispatch(loginRequest());

    try {
      const user = await login(values);
      const d = {}

      for (let [key, value] of Object.entries(user)) {
        if (value !== null && value !== ''){
          d[key]=value
        }
      }
      await dispatch(loginSuccess(d)); 
      history.push('/dashboard/home');    
    } catch (error) {
      let er;
      if (error && error.data && error.data.errors[0]) er = error.data.errors[0];
      else er = 'Login Failed, Try again';
      dispatch(loginError(errors(er)));
      setTimeout(() => dispatch(loginError(null)), 6000);
    }
  };
}