export const GET_ITEM_REQUEST = 'GET_ITEM_REQUEST'
export const GET_ITEM_ERROR = 'GET_ITEM_ERROR'
export const GET_ITEM_SUCCESS = 'GET_ITEM_SUCCESS'
 

export const NEXT_PAGE = 'NEXT_PAGE'
export const PREVIOUS_PAGE = 'PREVIOUS_PAGE'

export const CURRENT_ITEM = 'CURRENT_ITEM'


export const UPDATE_ITEM_REQUEST = 'UPDATE_ITEM_REQUEST'
export const UPDATE_ITEM_ERROR = 'UPDATE_ITEM_ERROR'
export const UPDATE_ITEM_SUCCESS = 'UPDATE_ITEM_SUCCESS'
 