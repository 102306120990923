import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import App from './App'
import { configureStore } from './configureStore'
import * as serviceWorker from './serviceWorker';

render(
  <Provider store={configureStore()}>
    <App />
  </Provider>,
  document.getElementById('root')
)

// In progressive web App where you want to run things offline,
// you may need to change serviceWorker to register()
serviceWorker.unregister();


