module.exports = {
  authApi: require('./auth'),
  appImprovementsApi: require('./appImprovements'),
  fareInfoApi: require('./fareInfo'),
  feedBackApi: require('./feedBack'),
  ratingsApi: require('./ratings'),
  traficUpdatesApi: require('./traficUpdates'),
  paymentsApi: require('./payments'),
  promoCodeApi: require('./promoCode'),
  paymentAPIs: require('./paymentAPIs'),
  denialAPIs: require('./paymentAPIs/denial'),
  debtorAPIs: require('./paymentAPIs/debtor'),
  promoUsageApi: require('./promousage'),
  referralUsageApi: require('./referralusage'),
  paymentReversalApi: require('./paymentreversal'),
  smsApi: require('./sms'),
  halfPayment: require('./halfPayment'),
  users: require('./users'),
}