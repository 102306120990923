/** @format */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ShouldRender } from '../components/libs/ShouldRender';
import { resetLogin } from '../actions/auth';
import {
  Dropdown,
  Icon,
  Menu,
  Grid,
  Segment,
  Sidebar,
  Label,
  Button,
} from 'semantic-ui-react';
import { history } from '../configureStore';
import { User } from '../components/configs';

export default function (ComposedComponent) {
  class DashBoard extends Component {
    constructor(props) {
      super(props);
      this.props = props;
      this.state = {
        visible: false,
      };
    }

    handleItemClick = (item) => {
      this.setState({ activeItem: item });
      history.push(`/dashboard/${item}`);
      this.setState({ visible: false });
    };

    toggleWindow = () => {
      this.setState({ visible: !this.state.visible });
    };

    render() {
      const { activeItem } = this.state;
      const user = User.getUser() || {};

      if (!User.isLoggedIn())
        return <div>{ComposedComponent && <ComposedComponent />}</div>;

      return (
        <main>
          {/* Single use account: */}
          <ShouldRender if={User.isLoggedIn() && 33 === 444}>
            <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
              <Grid.Column style={{ maxWidth: 550, 'background-color': 'thistle' }}>
                <Segment style={{ padding: '40px' }}>
                  <Button inverted color='olive'>
                    MY Transactions History
                  </Button>
                </Segment>

                <Segment style={{ padding: '40px' }}>
                  <Button icon labelPosition='right'>
                    Activate my account
                    <Icon name='right arrow' />
                  </Button>
                </Segment>
              </Grid.Column>
            </Grid>
          </ShouldRender>

          <ShouldRender if={User.isLoggedIn()}>
            <Sidebar.Pushable as={Segment}>
              <Sidebar
                as={Menu}
                animation='overlay'
                icon='labeled'
                inverted
                vertical
                visible={this.state.visible}
                width='thin'
                scrollLock
              >
                <Menu.Item as='a' onClick={() => this.toggleWindow()}>
                  <Icon name='outdent' />
                </Menu.Item>
                <Label color='red' ribbon>
                  Passenger App
                </Label>

                <Menu.Item>
                  <Icon name='handshake' />
                  Trip Payments
                  <Menu.Menu>
                    <Menu.Item
                      name='Payments'
                      active={activeItem === 'payments'}
                      onClick={() => this.handleItemClick('payments')}
                    />

                    <Menu.Item
                      name='paymentreversal'
                      active={activeItem === 'paymentreversal'}
                      onClick={() => this.handleItemClick('paymentreversal')}
                    />

                    <Menu.Item
                      name='halfPayment'
                      active={activeItem === 'halfPayment'}
                      onClick={() => this.handleItemClick('halfPayment')}
                    />
                  </Menu.Menu>
                </Menu.Item>

                <Menu.Item
                  as='a'
                  active={activeItem === 'promousage'}
                  onClick={() => this.handleItemClick('promousage')}
                >
                  <Dropdown.Item icon='handshake' text='PromoUsage' />
                </Menu.Item>

                <Menu.Item
                  as='a'
                  active={activeItem === 'referralusage'}
                  onClick={() => this.handleItemClick('referralusage')}
                >
                  <Dropdown.Item icon='handshake' text='ReferralUsage' />
                </Menu.Item>

                <Menu.Item>
                  <Icon name='comments' color='green' />
                  From Clients
                  <Menu.Menu>
                    <Menu.Item
                      name='FeedBacks'
                      active={activeItem === 'feedbacks'}
                      onClick={() => this.handleItemClick('feedbacks')}
                    />
                    <Menu.Item
                      name='App Improvements'
                      active={activeItem === 'improvements'}
                      onClick={() => this.handleItemClick('improvements')}
                    />
                    <Menu.Item
                      name='Ratings'
                      active={activeItem === 'ratings'}
                      onClick={() => this.handleItemClick('ratings')}
                    />
                  </Menu.Menu>
                </Menu.Item>

                <Menu.Item
                  as='a'
                  onClick={() => this.handleItemClick('fares')}
                  active={activeItem === 'fares'}
                >
                  <Dropdown.Item icon='money bill alternate outline' text='Fare Info' />
                </Menu.Item>

                <Menu.Item
                  as='a'
                  onClick={() => this.handleItemClick('traficUpdates')}
                  active={activeItem === 'traficUpdates'}
                >
                  <Icon name='street view' color='teal' />
                  Trafic Updates
                </Menu.Item>

                <Menu.Item
                  as='a'
                  onClick={() => this.handleItemClick('Sms')}
                  active={activeItem === 'Sms'}
                >
                  <Icon name='comments' color='green' />
                  Sms
                </Menu.Item>

                <ShouldRender if={user !== undefined}>
                  <Menu.Item>
                  <Icon name='comments' color='green' />
                  Promocodes Section
                  <Menu.Menu>
                    <Menu.Item
                      name='Add Promocodes'
                      active={activeItem === 'promoCode'}
                      onClick={() => this.handleItemClick('promoCode')}
                    />
                    <Menu.Item
                      name='View Promocodes'
                      active={activeItem === 'promocodes'}
                      onClick={() => this.handleItemClick('promocodes')}
                    />
                  </Menu.Menu>
                </Menu.Item>
                </ShouldRender>

                <Menu.Item
                  as='a'
                  onClick={() => this.handleItemClick('users')}
                  active={activeItem === 'users'}
                >
                  <Icon name='street view' color='teal' />
                  Users
                </Menu.Item>

                {/* Cards */}

                <Label color='red' ribbon>
                  Cards / Payments
                </Label>

                <Menu.Item
                  as='a'
                  onClick={() => this.handleItemClick('transactions')}
                  active={activeItem === 'transactions'}
                >
                  <Dropdown.Item
                    icon='nintendo switch'
                    text='Transactions'
                    fitted='horizontally'
                  />
                </Menu.Item>

                <Menu.Item
                  as='a'
                  onClick={() => this.handleItemClick('authorizations')}
                  active={activeItem === 'authorizations'}
                >
                  <Dropdown.Item
                    icon='calendar check'
                    text='Authorization Requests'
                    fitted='horizontally'
                  />
                </Menu.Item>

                {/* <Menu.Item
                  as='a'
                  onClick={() => this.handleItemClick('denialList')}
                  active={activeItem === 'denialList'}
                >
                  <Icon name='cut' color='red' />
                  Denial List
                </Menu.Item> */}

                {/* <Menu.Item
                  as='a'
                  onClick={() => this.handleItemClick('debtors')}
                  active={activeItem === 'debtors'}
                >
                  <Dropdown.Item icon='thumbs down outline' text='debtors' fitted='horizontally' />
                </Menu.Item> */}

                <ShouldRender if={user.super_admin}>
                  <Menu.Item
                    name='merchantAccounts'
                    active={activeItem === 'merchantAccounts'}
                    onClick={() => this.handleItemClick('merchantAccounts')}
                  />
                </ShouldRender>
              </Sidebar>

              <Menu pointing secondary style={{ left: 0, top: 0 }}>
                <Menu.Item onClick={() => this.toggleWindow()}>
                  <Icon name='indent' />
                </Menu.Item>

                <Menu.Menu position='left'>
                  <Menu.Item onClick={() => this.handleItemClick('home')}>HOME</Menu.Item>
                </Menu.Menu>

                <Menu.Menu position='right'>
                  <Menu.Item onClick={() => this.props.resetLogin()}>
                    <Button as='a' positive>
                      Log Out
                    </Button>
                  </Menu.Item>
                </Menu.Menu>
              </Menu>

              <Sidebar.Pusher style={{ overflow: 'scroll', height: '85vh' }}>
                <Segment basic>
                  <Grid.Column width={14}>
                    <ComposedComponent />
                  </Grid.Column>

                  {/* <footer style={{ 'margin-top': '30px' }}>
                  <div class='ui fluid one item menu'>
                    Copyright © {new Date().getFullYear()}{' '}
                    <a href='https://day.co.ke' title='DAY'>
                      Donholm Youth Platfrom
                    </a>
                    . All rights reserved.
                  </div>
                </footer> */}
                </Segment>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </ShouldRender>
        </main>
      );
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ resetLogin }, dispatch);
  };

  const mapStateToProps = (state) => {
    return {
      auth: state.auth,
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(DashBoard);
}
