/** @format */

import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { routerReducer } from 'react-router-redux';
import auth from './auth';
import item from './item';
import metric from './metric';
import promoCode from './promoCode';
/** Payments */
import denialList from './payments/denialList';
import payMain from './payments/payMain';
import merchants from './payments/merchants';

import { authInitialState } from './auth';
import { itemInitialState } from './item';
import { metricsInitialState } from './metric';
import { codeInitialState } from './promoCode';
/** Payments */
import { DenialListInitial } from './payments/denialList';
import { transactionsInitial } from './payments/payMain';
import { merchantsInitialState } from './payments/merchants';

const initialState = {
  auth: authInitialState,
  item: itemInitialState,
  metric: metricsInitialState,
  promoCode: codeInitialState,
  denialList: DenialListInitial,
  payMain: transactionsInitial,
  merchants: merchantsInitialState,
};

const appReducer = combineReducers({
  form: formReducer,
  routing: routerReducer,
  auth,
  item,
  metric,
  promoCode,
  denialList,
  payMain,
  merchants,
});

export default (state, action) => {
  if (action.type === 'CLEAR_STORE') {
    localStorage.clear();
    sessionStorage.clear();
    state = initialState;
  }
  return appReducer(state, action);
};
