import { applyMiddleware, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import monitorReducersEnhancer from './enhancers/monitorReducer'
import { routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import loggerMiddleware from './middleware/logger'
import rootReducer from './reducers'

// variable
export const history = createBrowserHistory();

// function
export function configureStore(preloadedState) {
  const middlewares = [loggerMiddleware, thunkMiddleware, routerMiddleware(history)]
  const middlewareEnhancer = applyMiddleware(...middlewares)
  const enhancers = [middlewareEnhancer, monitorReducersEnhancer]
  const composedEnhancers = composeWithDevTools(...enhancers)
  const store = createStore(rootReducer, preloadedState, composedEnhancers)

  // With hot module reloading enabled, only the reducer you changed would be reloaded, 
  // allowing you to change your code without resetting the state every time. 
  // if (process.env.NODE_ENV !== 'production' && module.hot) {
  //   module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
  // }

  return store
}
