import * as types from '../../constants/payments/merchants';

export const merchantsInitialState = {
  requesting: false,
  success: false,
  error: null,
  data: [],
};

export default (state = merchantsInitialState, action) => {
  switch (action.type) {
    case types.GET_MER_REQUEST:
      return {
        ...state,
        requesting: true,
        success: false,
        error: null,
      };

    case types.GET_MER_ERROR:
      return {
        ...state,
        requesting: false,
        success: false,
        error: action.payload,
      };

    case types.GET_MER_SUCCESS:
      return {
        ...state,
        requesting: false,
        success: true,
        error: null,
        data: action.payload,
      };

    default:
      return state;
  }
};
