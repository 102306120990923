export const User = {
	getUser() {
		const session = localStorage.getItem('session');
		return JSON.parse(session);
	},

	setUser(user) {
		return localStorage.setItem('session', JSON.stringify(user));
  },
  
  getToken() {
		return localStorage.getItem('token');
	},

	setToken(token) {
		localStorage.setItem('token', token);
	},


	loggedIn(token) {
		localStorage.setItem('loggedIn', token);
	},
	
	isLoggedIn() {
		return localStorage.getItem('loggedIn') ? true : false;
	},

};
