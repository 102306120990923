import { resetLogin } from '../src/actions/auth';

export default error => {
	if (typeof error === 'string') {
		if (error === 'Token verification Failed'){
		return resetLogin()
		}
		return `Error: ${error}`;
	}
	else if(window.addEventListener('offline', {offline:'offline'})){
		return 'Error: Check your network connection';
	} else {
		return 'Error: Check your connection !'
	}
};
 