/** @format */

import * as types from '../../constants/payments/denialList';

export const DenialListInitial = {
  get: {
    requesting: false,
    success: false,
    error: null,
    data: {},
  },
  submit: {
    requesting: false,
    success: false,
    error: null,
  },
};

export default (state = DenialListInitial, action) => {
  switch (action.type) {
    case types.REQUEST_CHECKIF_CLEARED:
      return {
        ...state,
        get: {
          requesting: true,
          success: false,
          error: null,
        },
      };

    case types.ERROR_CHECKIF_CLEARED:
      return {
        ...state,
        get: {
          requesting: false,
          success: false,
          error: action.payload,
        },
      };

    case types.SUCCESS_CHECKIF_CLEARED:
      return {
        ...state,
        get: {
          requesting: false,
          success: true,
          error: null,
          data: action.payload,
        },
      };

    /** SUBMIT */

    case types.REQUEST_SUBMIT_APPROVE:
      return {
        ...state,
        submit: {
          requesting: true,
          success: false,
          error: null,
        },
      };

    case types.ERROR_SUBMIT_APPROVE:
      return {
        ...state,
        submit: {
          requesting: false,
          success: false,
          error: action.payload,
        },
      };

    case types.SUCCESS_SUBMIT_APPROVE:
      return {
        ...state,
        submit: {
          requesting: false,
          success: true,
          error: null,
        },
      };

    default:
      return state;
  }
};
