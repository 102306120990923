/** @format */

import axios from 'axios';
import { User } from '../components/configs';
import dotenv from 'dotenv';
dotenv.config();

let url =
  process.env.NODE_ENV === 'development'
    ? process.env.DEVELOPMENT_URL || 'http://127.0.0.1:8891/'
    : process.env.LIVE_URL || 'https://test.api.mobitill.com/';

const login = (values) => {
  let accessurl = `${url}login`;
  let payload = {
    data: {
      user: values.username || values.email,
      password: values.password,
    },
  };

  return axios
    .post(accessurl, payload)
    .then((response) => {
      const { token, data } = response.data;
      User.loggedIn(true)
      User.setToken(token);
      return data;
    })
    .catch((error) => {
      throw error.response;
    });
};

const validateAccessToken = () => {
  let token = User.getToken();

  let accessurl = `${url}validateToken`;
  return axios
    .post(accessurl, {}, { headers: { token: token } })
    .then((data) => {
      return data.data.data;
    })
    .catch((error) => {
      throw error.response;
    });
};


const validateUserByLoginCheck = (values) => {
  let accessurl = `${url}login`;
  let payload = {
    data: {
      user: values.email,
      password: values.password,
    },
  };

  return axios
    .post(accessurl, payload)
    .then((response) => {
      const { token } = response.data;
      return token;
    })
    .catch((error) => {
      throw error.response;
    });
};


export { login, validateAccessToken, validateUserByLoginCheck };
