/** @format */

import * as types from '../../constants/payments/transactions';

export const transactionsInitial = {};

export default (state = transactionsInitial, action) => {
  switch (action.type) {
    case types.GET_TRANSACTIONS_REQUEST:
      return {
        ...state,
        [action.payload]: {
          requesting: true,
          success: false,
          error: null,
        },
      };

    case types.GET_TRANSACTIONS_ERROR:
      return {
        ...state,
        [action.payload.itemCalled]: {
          requesting: false,
          success: false,
          error: action.payload.error,
        },
      };

    case types.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        [action.payload.itemCalled]: {
          requesting: false,
          success: true,
          error: null,
          data: action.payload.data,
        },
      };

      // pages
      case types.NEXT_PAGE_TRANSACTIONS:
        return {
          ...state,
          [action.payload.itemCalled]: {
          nextPageTransactions: action.payload.page,
          }
        };

        case types.PREV_PAGE_TRANSACTIONS:
          return {
            ...state,
            [action.payload.itemCalled]: {
            prevPageTransactions: action.payloadpage,
            }
          };

    default:
      return state;
  }
};
