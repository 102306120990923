/** @format */

import axios from "axios";
import { User } from "../components/configs";
require("dotenv").config();

let url =
  process.env.NODE_ENV === "development"
    ? process.env.DEVELOPMENT_URL || "http://127.0.0.1:2222/"
    : process.env.LIVE_URL || "https://test.passengerapi.mobitill.com/";

const getUsers = () => {
  let accessurl = `${url}dash/users`;
  const retunToken = User.getToken();
  const token = {
    headers: { Authorization: "Bearer " + retunToken },
  };

  return axios
    .get(accessurl, { headers: token.headers })
    .then((data) => {
      console.log('usersdata', data.data)
      return data.data
    })
    .catch((error) => {
      throw error.response;
    });
};

export { getUsers };
