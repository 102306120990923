import * as types from '../constants/metric';

export const metricsInitialState = {
  requesting: false,
  success: false,
  error: null,
  data: [],
};

export default (state = metricsInitialState, action) => {
  switch (action.type) {
    case types.METRIC_REQUEST:
      return {
        ...state,
        requesting: true,
        success: false,
        error: null,
      };

    case types.METRIC_ERROR:
      return {
        ...state,
        requesting: false,
        success: false,
        error: action.payload,
      };

    case types.METRIC_SUCCESS:
      return {
        ...state,
        requesting: false,
        success: true,
        error: null,
        data: action.payload,
      };

    default:
      return state;
  }
};
