/** @format */

import axios from 'axios';
import { User } from '../components/configs';
require('dotenv').config();

let url =
  process.env.NODE_ENV === 'development'
    ? process.env.DEVELOPMENT_URL || 'http://127.0.0.1:2222/'
    : process.env.LIVE_URL || 'https://test.passengerapi.mobitill.com/';


const getImprovementsByRange = (values) => {
  let accessurl = `${url}dash/improvements/range`;

  const retunToken = User.getToken();
  const token = {
    headers: { Authorization: 'Bearer ' + retunToken },
  };

  return axios
    .get(accessurl, { params: { ...values }, headers: token.headers })
    .then((data) => data.data)
    .catch((error) => {
      throw error.response;
    });
};

export { getImprovementsByRange };
