/** @format */

import axios from 'axios';
require('dotenv').config();

let url = 'http://masstransit.eu-west-1.elasticbeanstalk.com/'

const getDebtor = async (data) => {
  let accessurl = `${url}api/tap/getalltaps`;
   return axios
    .post(accessurl, { "size":30 })
    .then((data) => data)
    .catch((error) => {
      throw error.response;
    });
};

export { getDebtor };
