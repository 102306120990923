/** @format */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { history } from '../configureStore';
import { User } from '../components/configs';

const withSecretToLife = (WrappedComponent) => {
  class HOC extends React.Component {
    constructor(props) {
      super(props);
      this.props = props;

      this.isAuthenticated = User.isLoggedIn();
    }

    componentDidMount() {
      if (!this.isAuthenticated) {
        history.push('/login');
      }
    }

    render() {
    
      return (
        <div>
          <WrappedComponent {...this.props} />
        </div>
      );
    }
  }

  const mapStateToProps = (state) => {
    return {
      auth: state.auth,
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
  };

  return connect(mapStateToProps, mapDispatchToProps)(HOC);
};

export default withSecretToLife;
